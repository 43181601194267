.pre-load-container {
  height: 100vh;
}
.success-redirect-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.home-page-container {
  display: flex;
  justify-content: center;
  align-items: center;
}
.home-page-card-container {
  max-width: 1060px;
  padding: 0px 15px 15px 15px;
}
.header-text {
  color: white;
}
.header-container {
  padding: 10px;
  display: flex;
  margin-bottom: 10px;
  justify-content: center;
  align-items: center;
  background-color: #5f951a;
  border-radius: 5px 5px 0px 0px;
}

.read-more-btn {
  margin-left: 4px;
  color: blue;
  cursor: pointer;
}

@media (min-width: 576px) {
}

@media (min-width: 768px) {
}

@media (min-width: 992px) {
  .home-page-card-container {
    margin-top: 66px;
  }
}

@media (min-width: 1200px) {
  .home-page-card-container {
    margin-top: 66px;
  }
}
