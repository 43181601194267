.slot-item-parent-container {
  display: flex;
  /* max-height: 400px; */
  justify-content: center;
  overflow-y: scroll;
}
.slot-item-container {
  /* width: 180px; */
  height: 52px;
  display: flex;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
  padding: 5px 10px 5px 10px;
  border-radius: 10px;
  border: 1px solid rgba(0, 105, 255, 0.5);
  color: rgb(0, 105, 255);
  line-height: 1.5;
  word-break: break-word;
  overflow-wrap: break-word;
  -webkit-font-smoothing: antialiased;
}
.slot-item-container:hover {
  border: 2px solid #0060e6;
}
.no-slot-found-text {
  color: #dc3545;
}
.no-slot-date-text {
  color: #17a2b8;
}
.slot-item-text {
  font-weight: bold;
}
@media (min-width: 576px) {
}

@media (min-width: 768px) {
}

@media (min-width: 992px) {
}

@media (min-width: 1200px) {
}
