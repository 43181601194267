.top-nav-container {
  display: flex;
  align-items: center;
  margin: 0px 5px 5px 5px;
  justify-content: space-between;
}

.button-next,
.button-prev {
  height: 30px;
  width: 30px;
  padding: 2px;
  cursor: pointer;
}

.button-prev:hover,
.button-next:hover {
  border-radius: 50%;
  background-color: rgba(60, 151, 232, 0.7);
}

table {
  border-collapse: collapse;
  background: white;
  color: black;
}
.weekHeader {
  color: black;
  background-color: rgba(60, 151, 232, 0.2);
}

th {
  font-weight: bold;
}

.date-td {
  cursor: pointer;
}

.date {
  display: flex;
  color: black;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
}
.date:hover {
  color: black;
  background-color: rgba(60, 151, 232, 0.1);
}
.month-calendar-indicator {
  height: 8px;
  width: 8px;
  background-color: #3c97e8;
  border-radius: 50%;
  margin-left: auto;
  margin-right: auto;
}
.active {
  background-color: rgba(60, 151, 232, 0.1);
}
.selectedDate {
  color: white;
  background-color: #0060e6;
}
.date-and-indicator-container {
  text-align: center;
}
.selectedDate:hover {
  color: white;
  background-color: #0060e6;
}

.disabled {
  color: #999999;
}
.disabled:hover {
  color: #999999;
  background-color: white;
}
